import React, { useState } from 'react';
import logo from './assets/images/logo.jpg';
import style from './App.module.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const oilCompanies =[
  {
    name: "Exxon Mobile",
    location: "Affordable and sustainable energy solutions are required to advance global prosperity.",
    projects: [
      {
        slug:"covid_relieve",
        code:"5800.01",
        title_of_code:"CD - Education",
        category: "Health",
        description: "",
        contract_sum: 30000000,
        yearly_budget: 5000000,
        yearly_scope_of_work: <a href="#">View document</a>,
        actual_performance: "50%",
        start_date: "02/02/2020",
        end_date: "03/07/2020"
      },{
        slug:"cleaner_power",
        code:"5800.02",
        title_of_code:"CD - Road",
        category: "Infrastructure",
        description: "",
        contract_sum: 30000000,
        yearly_budget: 5000000,
        yearly_scope_of_work: <a href="#">View document</a>,
        actual_performance: "50%",
        start_date: "02/02/2020",
        end_date: "03/07/2020"
      }
    ]
  },
  {
    name: "Chevron Corporation",
    location: "6001 Bollinger Canyon Road San Ramon, CA 94583, USA",
    projects: [
      {
        slug:"cd_education",
        code:"5800.01",
        title_of_code:"CD - Education",
        category: "Education",
        description: "",
        contract_sum: 10000000,
        yearly_budget: 5000000,
        yearly_scope_of_work: <a href="#">View document</a>,
        actual_performance: "40%",
        start_date: "01/01/2020",
        end_date: "21/11/2020"
      },{
        slug:"bonga_sw_aparo",
        code:"5800.02",
        title_of_code:"CD - Road",
        category: "Infrastructure",
        description: "",
        contract_sum: 30000000,
        yearly_budget: 5000000,
        yearly_scope_of_work: <a href="#">View document</a>,
        actual_performance: "50%",
        start_date: "02/02/2020",
        end_date: "03/07/2020"
      }
    ]
  },
  {
    name: "ADDAX PETROLEUM DEVELOPMENT",
    location: "Nigerian petroleum company"
  },
  {
    name: "AGIP ENERGY & NATURAL RESOURCES",
    location: "Nigerian energy & natural resources company"
  },
  {
    name: "BRASS EXPLORATION ",
    location: "Nigerian exploration company"
  },
  {
    name: "CONOCO ENERGY NIGERIA",
    location: "Nigerian energy company"
  },
  {
    name: "AGIP ENERGY & NATURAL RESOURCES",
    location: "Nigerian energy & natural resources company"
  }
]

const Project = ({match})=>{
  const {company} = match.params;
  const companyDetails = oilCompanies.find(item=>item.name == company);
  // const projectDetails = companyDetails ? companyDetails.find(item=>item.slug == project) : null;
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Codes</th>
          <th>Title of Codes</th>
          <th>Name of Asset</th>
          <th>Project Category</th>
          <th>Project Description</th>
          <th>Contract Sum</th>
          <th>Budget for the Year</th>
          <th>Scope of work for the year</th>
          <th>Actual Performance</th>
          <th>Project Start Date</th>
          <th>Project End Date</th>
          <th>Special Remarks</th>
        </tr>
      </thead>
      <tbody>
        {
          !companyDetails.projects || companyDetails.projects.map((project,i)=>
            <tr>
              <td>{project.code}</td>
              <td>{project.title_of_code}</td>
              <td></td>
              <td>{project.category}</td>
              <td>{project.description}</td>
              <td>{project.contract_sum}</td>
              <td>{project.yearly_budget}</td>
              <td>{project.yearly_scope_of_work}</td>
              <td>{project.actual_performance}</td>
              <td>{project.start_date}</td>
              <td>{project.end_date}</td>
              <td></td>
            </tr>
            )
        }
      </tbody>
    </table>
  )
}
const ProjectAlt = ({match})=>{
  const {company,project} = match.params;
  const companyDetails = oilCompanies.find(item=>item.name == company);
  console.log(companyDetails)
  const projectDetails = companyDetails ? companyDetails.projects.find(item=>item.slug == project) : null;
  return (
    <div style={{textAlign:"center"}}>
      <h3>Project Details</h3>
      <p>Category: {projectDetails.category}</p>
      <p>Description: {projectDetails.description}</p>
      <p>Contract Sum: {projectDetails.contract_sum}</p>
      <p>Yearly Budget: {projectDetails.yearly_budget}</p>
      <p>Yearly Scope of work: {projectDetails.yearly_scope_of_work}</p>
      <p>Actual Performance: {projectDetails.actual_performance}</p>
      <p>Start Date: {projectDetails.start_date}</p>
      <p>End Date: {projectDetails.end_date}</p>
      
    </div>
  )
}
const Card = ({company})=>{
    const [open, setOpen] = useState(0);
    return (
      <div className="col-md-6">
        <button className="d-flex flex-column btn btn-success w-100 mt-3" onClick={()=>setOpen(!open)}>
          <p>{company.name}</p>
          <small>{company.location}</small>
        </button>
        {open ? <div className="p-3">
            <h4><Link to={`project/${company.name}`}>Projects</Link></h4>
            <ul>
              {company.projects && company.projects.map(project=>
                <li>
                  {project.description}
                </li>
              )}
            </ul>
          </div>
          : ""
        } 
      </div>
    )
}
const CardAlt = ({company})=>{
    const [open, setOpen] = useState(0);
    return (
      <div className="col-md-6">
        <button className="d-flex flex-column btn btn-success w-100 mt-3" onClick={()=>setOpen(!open)}>
          <p>{company.name}</p>
          <small>{company.location}</small>
        </button>
        {open ? <div className="p-3">
            <h4>Projects</h4>
            <ul>
              {company.projects.map(project=>
                <li>
                  <Link to={`project/${company.name}/${project.slug}`}>{project.description}</Link>
                </li>
              )}
            </ul>
          </div>
          : ""
        } 
      </div>
    )
}

function App() {
  const [list, setList] = useState(0)
  return (
    <Router>
    <div className={style._}>
      <Link to="/">
        <header className="" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <img src={logo} className={style.__logo} alt="Logo"/>
          <span>NATIONAL PETROLEUM INVESTMENT MANAGEMENT SERVICES</span>
        </header>
      </Link>
      <div className={`container ${style.__main}`}>
            <Switch>
              <Route exact path="/">
                <h2 style={{cursor:"pointer"}} className="text-center mt-5" onClick={()=>setList(!list)}>Operators / Partners <i className="fa fa-caret-down"></i></h2>
                {list ? <div className="row">
                  {oilCompanies.map((company, index)=> 
                      <Card company={company}/>
                    )}
                </div>:""}
              </Route>
              <Route exact path="/project/:company"  component={Project}/>
              <Route exact path="/project/:company/:project"  component={ProjectAlt}/>
            </Switch>
      </div>
    </div>
          </Router>
  );
}

export default App;
